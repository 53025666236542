//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class buildingSpaces {
 
  list(data){
    return http.get( api.building_spaces.get.endpoint, data);
  }

  create(data){
    return http.post( api.building_spaces.create.endpoint, null , data );
  }
  
  update(uuid,data){
    return http.put( api.building_spaces.update.endpoint+uuid, data);
  }
  
  delete(data){
    return http.delete( api.building_spaces.delete.endpoint+data );
  }

}

export default new buildingSpaces();